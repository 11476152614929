import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "error"]

  connect() {
    const input = document.querySelector(`#${this.inputTarget.id}`)
    window.intlTelInput(input, {
      onlyCountries: ["us"],
      allowDropdown: false,
      showFlag: false,
      strictMode: true,
      loadUtilsOnInit: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.6.1/build/js/utils.js",
    })
  }

  validatePhoneNumber() {
    const phonePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/
    if (!this.inputTarget.value || phonePattern.test(this.inputTarget.value)) {
      this.errorTarget.textContent = ""
    } else {
      this.errorTarget.textContent = "Please enter a valid 10-digit phone number."
    }
  }
}

